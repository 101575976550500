function calculateTimeDifference(vipDateString) {  
    // 将字符串转换为Date对象  
    const vipDate = new Date(vipDateString);  
  
    // 获取当前日期和时间  
    const now = new Date();  
  
    // 计算时间差（毫秒）  
    const diff = vipDate - now;  
  
    // 将毫秒差转换为天数  
    const daysDiff = Math.floor(diff / (1000 * 60 * 60 * 24));  
  
    // 剩余的毫秒数（用于计算小时数、分钟数和秒数）  
    const remainingMillis = diff % (1000 * 60 * 60 * 24);  
  
    // 将剩余的毫秒数转换为小时数  
    const hoursDiff = Math.floor(remainingMillis / (1000 * 60 * 60));  
  
    // 剩余的毫秒数（用于计算分钟数和秒数），减去已经用于计算小时数的部分  
    const remainingMillisForMinutesAndSeconds = remainingMillis % (1000 * 60 * 60);  
  
    // 将剩余的毫秒数转换为分钟数  
    const minutesDiff = Math.floor(remainingMillisForMinutesAndSeconds / (1000 * 60));  
  
    // 剩余的毫秒数（仅用于计算秒数），减去已经用于计算分钟数的部分  
    const remainingMillisForSeconds = remainingMillisForMinutesAndSeconds % (1000 * 60);  
  
    // 将剩余的毫秒数转换为秒数  
    const secondsDiff = Math.floor(remainingMillisForSeconds / 1000);  
  
    // 返回天数、小时数、分钟数和秒数的对象  
    return {  
        days: daysDiff,  
        hours: hoursDiff,  
        minutes: minutesDiff,  
        seconds: secondsDiff,  
    };  
}  
function formatDate(time) {
    var date = new Date(time);
    var YY = date.getFullYear() + "-";
    var MM =
        (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
    var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    var hh =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    var mm =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
    var ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return YY + MM + DD + " " + hh + mm + ss;
}
export default {
    calculateTimeDifference,
    formatDate
}