<template>
  <div id="app">
    <el-container>
      <el-header class="headerTex">
        <div class="leftbox">
          会员有效期：<span>{{ viptime.days }}</span
          >天<span>{{ viptime.hours }}</span
          >小时 <span>{{ viptime.minutes }}</span
          >分钟 <span>{{ viptime.seconds }}</span
          >秒
        </div>
        <b>合赢科技文章智能助手</b>
        <div class="rightbox">
          <img src="../assets/aaa.png" alt="" />
          <el-dropdown>
            <span class="el-dropdown-link">
              {{ userinfo.phone }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                ><div @click="loginout">退出登录</div></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-container>
        <el-aside width="200px">
          <div class="buzhou">
            <span
              >今日剩余次数: <b>{{ sendnum }}</b> 次</span
            >

            <!-- 问答步骤：输入文章到以下输入框中点击发送即可 -->
            <p>1、复制文章到输入框内</p>
            <p>2、点击发送按钮即可</p>
            <p>3、等待2-5分钟即可得到结果</p>
            <p>4、生成文章时不要刷新页面，否则生成失败</p>
            <p>5、若生成结果不合适，可再次生成一次</p>

            <span style="font-size: 10px; color: #ed7171"
              >若长时间未响应，请刷新页面后重试</span
            >
          </div>
        </el-aside>
        <el-container>
          <el-main>
            <div class="scorllbox" ref="scrollBox">
              <div v-for="(item, index) in datalist" :key="index" class="aibox">
                <div class="aicontent" v-if="item.role == 'assistant'">
                  <v-md-editor
                    v-model="item.content"
                    mode="preview"
                  ></v-md-editor>
                </div>

                <div class="usercontent" v-else>
                  {{ item.content }}
                </div>
                <div class="copyheng">
                  <div
                    class="copybtn"
                    v-if="item.role == 'assistant'"
                    @click="copyShareLink"
                  >
                    复制
                  </div>
                </div>
              </div>
            </div>
          </el-main>
          <el-footer style="height: 120px">
            <div class="inputbox">
              <div class="input-group">
                <textarea
                  name="Email"
                  id="Email"
                  class="input"
                  rows="4"
                  placeholder="请复制文章到此处，然后点击发送等待结果，发送字数少于3000字"
                  v-model="textarea"
                  :disabled="loading"
                  maxlength="3000"
                ></textarea>
              </div>
              <div class="butn">
                <el-button
                  style="width: 80%"
                  @click="submit"
                  type="primary"
                  :loading="loading"
                >
                  发送</el-button
                >
              </div>
            </div>
          </el-footer>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      textarea: "",
      loading: false,
      datalist: [],
      message: "",
      userinfo: "",
      vipday: "",
      viphour: "",
      ai_res_content: "",
      sendnum: 0,
      viptime: {},
    };
  },
  computed: {},
  created() {},
  mounted() {
    if (!window.localStorage.getItem("token")) {
      this.$router.replace("/login");
    }

    this.getuserinfo().then(() => {
      this.getsendnum();
    });
  },
  methods: {
    async copyShareLink() {
      try {
        await navigator.clipboard.writeText(
          this.ai_res_content.replace(/\*/g, "")
        );
        this.$message.success("已复制到剪切板");
      } catch (err) {
        this.$message.error("复制失败");
      }
    },
    scrollToBottom() {
      const scrollBox = this.$refs.scrollBox;
      // console.log(scrollBox);
      scrollBox.scrollTop = scrollBox.scrollHeight;
    },
    async getuserinfo() {
      return new Promise((resolve, reject) => {
        this.$http("/BD/getuser", "post", {
          id: this.userinfo.id,
        }).then((res) => {
          // console.log(res, 111);
          if (res.data.code == 1) {
            this.userinfo = res.data.userInfo;

            if (res.data.userInfo.is_vip == 0) {
              this.$router.replace("/login");
              this.$message.error("您的会员已过期，请联系您的指导老师");
              return;
            } else {
              this.vipdate = this.$utils.formatDate(
                Date.parse(this.userinfo.vipdate)
              );

              this.viptime = this.$utils.calculateTimeDifference(this.vipdate);

              if (
                this.viptime.seconds <= 0 &&
                this.viptime.minutes < 0 &&
                this.viptime.hours < 0 &&
                this.viptime.days < 0
              ) {
                this.$router.replace("/login");
                this.$message.error("您的会员已过期，请联系您的指导老师");
              }
            }
            resolve(res);
          } else {
            reject(res);
          }
        });
      });
    },
    getsendnum() {
      this.$http("/BD/getsendnum", "post", {
        id: this.userinfo.id,
      }).then((res) => {
        // console.log(res, 222);
        if (res.data.code == 1) {
          if (res.data.data) {
            this.sendnum = this.userinfo.send_num - res.data.data[0].num;
            if (this.sendnum <= 0) {
              this.sendnum = 0;
            }
          } else {
            this.sendnum = this.userinfo.send_num;
          }
        }
      });
    },
    loginout() {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("userInfo");
      this.$router.replace("/login");
    },
    submit() {
      // console.log(this.textarea);
      if (!this.textarea || this.textarea == "") {
        this.$message.error("请输入内容");
        return;
      }

      if (this.sendnum <= 0) {
        this.$message.error("今日无次数");
        return;
      }

      this.loading = true;

      this.getuserinfo().then((res) => {
        // console.log(res);
        if (res.data.code == 1 && this.userinfo.is_vip == 0) {
          this.$message.error("您的会员已过期，请联系您的指导老师");
          this.$router.replace("/login");
          return;
        } else {
          this.datalist.push({
            content: this.textarea,
            role: "user",
          });
          this.message = this.textarea;
          this.textarea = "";
          this.$message.success("发送成功,请稍后");
          // return;
          setTimeout(() => {
            this.scrollToBottom();
          }, 500);
          this.$http("/BD/sendtext", "post", {
            message: this.message,
            id: this.userinfo.id,
          })
            .then((res) => {
              this.loading = false;
              // console.log(res);
              if (res.data.code == 1) {
                this.datalist.push({
                  content: res.data.data.result.replace(/#/g, ""),
                  role: "assistant",
                });
                this.ai_res_content = res.data.data.result.replace(/#/g, "");
                this.getsendnum();
                this.$notify({
                  title: "生成成功",
                  message: "文章结果已生成",
                  type: "success",
                });
                setTimeout(() => {
                  this.scrollToBottom();
                }, 500);
              } else {
                this.$message.error(res.data.msg);
              }
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
              this.$message.error("发送失败");
            });
        }
      });
      //   return;
    },
  },
};
</script>

<style lang="scss">
.el-header,
.el-footer {
  background-color: #cee0f7;
  color: #333;
  text-align: center;
  height: 60px;
}

.el-footer {
  padding: 0;
}

.el-aside {
  background-color: #fff;
  color: #333;
  text-align: center;
}

.buzhou {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  padding-top: 10px;
  box-sizing: border-box;
  b {
    color: #f35f5f;
  }
  p {
    text-align: left;
    margin: 10px 0;
  }
}

.el-main {
  //   background-color: #e9eef3;
  color: #333;
  text-align: center;
  height: calc(100vh - 180px);
  //   background: url("../assets/main_bg.jpg") no-repeat;
  //   background-size: cover;

  background: linear-gradient(
    80deg,
    #edf9fe,
    #d9ecf8,
    #c9e9f5,
    #c4def0,
    #e3edf9
  );
  background-size: 500% 500%;
  animation: gradientBG 5s ease infinite;

  @keyframes gradientBG {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.inputbox {
  width: 100%;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;

  .butn {
    min-width: 120px;
  }

  .input-group {
    width: 100%;
    height: 100%;
  }
}

.scorllbox {
  overflow-y: auto;
  height: calc(100vh - 220px);
  width: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  .aibox {
    width: 100%;
    border-radius: 10px;

    & > div {
      & > div {
        & > div {
          & > div {
            border-radius: 24px;
            & > div {
              & > div {
                & > div {
                  & > div {
                    & > div {
                      & > div {
                        padding: 12px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  //   /* 滚动条整体宽度 */
  //   ::-webkit-scrollbar {
  //     width: 10px;
  //     /* 对垂直滚动条有效 */
  //     height: 10px;
  //     /* 对水平滚动条有效 */
  //   }

  //   /* 滚动条滑块 */
  //   ::-webkit-scrollbar-thumb {
  //     background: #888;
  //     /* 滑块颜色 */
  //     border-radius: 10px;
  //     /* 滑块圆角 */
  //   }

  //   /* 滚动条轨道 */
  //   ::-webkit-scrollbar-track {
  //     background: #f1f1f1;
  //     /* 轨道颜色 */
  //     border-radius: 10px;
  //     /* 轨道圆角 */
  //   }

  .aicontent {
    // flex: 1;
    // width: 100%;
    text-align: left;
    padding: 10px;
    border-radius: 10px;
    box-sizing: border-box;
    // width: 1600px;
  }

  .copyheng {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;

    .copybtn {
      width: 50px;
      padding: 4px;
      border: 1px solid #fff;
      border-radius: 4px;
      cursor: pointer;
      font-weight: bold;
    }
  }

  .usercontent {
    margin-bottom: 30px;
    padding: 10px;
    border-radius: 10px;
    background: #e8e8e8;
    box-sizing: border-box;
    text-align: left;

    border-radius: 2px 10px 10px 10px;
    background: transparent;
    border: 1px solid #fff;

    --webkit-backdrop-filter: blur(30px);
  }
}

.userIpt {
  width: 100%;
  min-height: 120px;
  max-height: 120px;
  position: relative;
  border: 1px solid #fff;
  border-radius: 6px;
  background: linear-gradient(
    80deg,
    rgba(245, 251, 254, 0.6),
    rgba(237, 246, 252, 0.6)
  );
  box-shadow: 10px 0 25px 0 #c8e1f3;
  box-sizing: border-box;
  display: flex;
}

textarea.input {
  resize: none;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
}

.headerTex {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  position: relative;

  .leftbox {
    position: absolute;
    left: 20px;
    top: 10px;
    height: 40px;
    color: #666;
    display: flex;
    align-items: center;
    font-size: 16px;
    span {
      color: #f35f5f;
    }
  }

  .rightbox {
    position: absolute;
    right: 20px;
    top: 10px;
    height: 40px;
    color: #aaa;
    display: flex;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 2px solid #fff;
      margin-right: 10px;
    }
  }
}
.scorllbox::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.scorllbox::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: skyblue;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}
.scorllbox::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(234, 232, 232, 0);
  background: #ebf5ff;
  border-radius: 10px;
}
</style>
