import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import utils from '../utils/utils'



import VMdEditor from "@kangc/v-md-editor"
import "@kangc/v-md-editor/lib/style/base-editor.css"
import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js"
import "@kangc/v-md-editor/lib/theme/style/vuepress.css"
import Prism from "prismjs"
import createCopyCodePlugin from "@kangc/v-md-editor/lib/plugins/copy-code/index"
import "@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css"
import createLineNumbertPlugin from "@kangc/v-md-editor/lib/plugins/line-number/index"
import createTodoListPlugin from "@kangc/v-md-editor/lib/plugins/todo-list/index"
import "@kangc/v-md-editor/lib/plugins/todo-list/todo-list.css"
VMdEditor.use(vuepressTheme, { Prism }).use(createCopyCodePlugin()).use(createLineNumbertPlugin()).use(createTodoListPlugin())
Vue.use(VMdEditor)

import request from '../request/request'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import VViewer from "@michaelray/vue-viewer";
Vue.use(VViewer);


Vue.use(ElementUI);

// 将axios挂载到Vue的原型上  
Vue.prototype.$axios = axios;
Vue.prototype.$http = request;
Vue.prototype.$utils = utils;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
