var BASE_URL = 'https://node.hkzyj.cn'
import router from '../src/router/index'
import axios from 'axios'

//请求封装
function sendRequest(url, method, data) {
    // console.log(url);

    let token = JSON.parse(window.localStorage.getItem('token')) || null

    var promise = new Promise((resolve, reject) => {


        axios({
            method, //请求方式
            url: BASE_URL + url, //URL
            data, //请求参数
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                token
            }
        }).then(res => {
            console.log("%c 链接成功... %c ", "background-color:#00aa00;color:#efefef", "color:orange;font-weight:bold");
            if (res.data.code === '999') {
                console.log('过期了')
                router.replace({ path: '/login' })
            } else if (res.data.code == 0) {
                this.$message({
                    message: res.data.msg,
                    type: 'error'
                });
                return
            } else {
                resolve(res);
            }
        })

    })
    return promise;
}

// module.exports.sendRequest =   //导出

export default sendRequest
